
































import {Component, Prop, Vue} from "vue-property-decorator";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import axios from "axios";

@Component({
  components: {TextInput, FormButton},
  props: ["publication", "expanded"],
})
export default class ControlPanelPublicationEntry extends Vue {
  @Prop()
  public publication: any;

  @Prop(Boolean)
  public expanded: boolean;

  public editTitle = this.publication.title;
  public editAuthor = this.publication.author;
  public editReference = this.publication.reference;
  public editLink = this.publication.link;

  public editTitleWarning = false;
  public editAuthorWarning = false;
  public editReferenceWarning = false;

  public toggleExpand() {
    this.$emit("toggleExpand", this.publication);
  }

  public savePublication() {
    if (this.validate()) {
      const changedPublication = {
        id: this.publication.id,
        title: this.editTitle,
        author: this.editAuthor,
        reference: this.editReference,
        link: this.editLink,
      };
      axios.patch("/controlpanel/publications/" + this.publication.id, changedPublication).then(() => {
        this.toggleExpand();
        this.$emit("edit", changedPublication);
      });
    }
  }

  public deletePublication() {
    if (confirm("Are you sure you want to delete this publication?")) {
      axios.delete("/controlpanel/publications/" + this.publication.id).then(() => {
        this.toggleExpand();
        this.$emit("delete", this.publication.id);
      });
    }
  }

  public validate() {
    this.editTitleWarning = !this.editTitle;
    this.editAuthorWarning = !this.editAuthor;
    this.editReferenceWarning = !this.editReference;

    return !this.editTitleWarning && !this.editAuthorWarning && !this.editReferenceWarning;
  }
}
