
































import {Component, Vue} from "vue-property-decorator";
import axios from "axios";
import ControlPanelPublicationEntry from "@/components/controlpanel/publications/ControlPanelPublicationEntry.vue";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import draggable from "vuedraggable";

@Component({
  components: {ControlPanelPublicationEntry, TextInput, FormButton, draggable},
})
export default class PublicationsControlPanel extends Vue {
  public publications: any[] = [];

  public expandedId: number = -1;

  public newPublicationTitle = "";
  public newPublicationAuthor = "";
  public newPublicationReference = "";
  public newPublicationLink = "";

  public newPublicationTitleWarning = false;
  public newPublicationAuthorWarning = false;
  public newPublicationReferenceWarning = false;

  public created() {
    axios.get("/controlpanel/publications").then((res) => {
      this.publications = res.data.items;
    });
  }

  public toggleExpand(publication: any) {
    if (publication.id == this.expandedId) {
      this.expandedId = -1;
    } else {
      this.expandedId = publication.id;
    }
  }

  public isExpanded(id: number) {
    return this.expandedId == id;
  }

  public addPublication() {
    if (this.verify()) {
      const newPublication = {
        title: this.newPublicationTitle,
        author: this.newPublicationAuthor,
        reference: this.newPublicationReference,
        link: this.newPublicationLink,
      };
      axios.post("/controlpanel/publications", newPublication).then((res) => {
        this.publications.unshift({id: res.data.id, ...newPublication});
        this.$modal.hide("add-publication");
        this.resetFields();
      });
    }
  }

  public editItem(changedPublication: any) {
    const original = this.publications.find((p) => p.id == changedPublication.id);
    original.title = changedPublication.title;
    original.author = changedPublication.author;
    original.reference = changedPublication.reference;
    original.link = changedPublication.link;
  }

  public deleteItem(id: number) {
    this.publications.splice(this.publications.findIndex((p) => p.id == id), 1);
  }

  get publicationlist() {
    return this.publications;
  }

  set publicationlist(val) {
    const newPublications = val.map((publication: any, index: any) => {
      return {id: publication.id, sequence: index};
    });
    axios.patch("/controlpanel/publications/order", {publications: newPublications}).then(() => {
      this.publications = val;
    });
  }

  public verify() {
    this.newPublicationTitleWarning = !this.newPublicationTitle;
    this.newPublicationAuthorWarning = !this.newPublicationAuthor;
    this.newPublicationReferenceWarning = !this.newPublicationReference;

    return !this.newPublicationTitleWarning && !this.newPublicationAuthorWarning &&
      !this.newPublicationReferenceWarning;
  }

  private resetFields() {
    this.newPublicationTitle = "";
    this.newPublicationTitleWarning = false;
    this.newPublicationAuthor = "";
    this.newPublicationAuthorWarning = false;
    this.newPublicationReference = "";
    this.newPublicationReferenceWarning = false;
    this.newPublicationLink = "";
  }
}
